import { atom } from "jotai";
import { INaverPlaceInfo } from "../endpoint/dtos/place";

export const signIn = atom<boolean | null>(null)

export interface IUserInfo {
  user_id: string,
  name: string,
  tier: string,
  placeId: string
  placeName: string,
  placeType: string
}

export const userInfo = atom<IUserInfo>({
  user_id: '',
  name: '',
  tier: '',
  placeId: '',
  placeName: '',
  placeType: ''
})

export const myPlaceInfo = atom<INaverPlaceInfo | undefined>(undefined);