import Cookies from 'js-cookie';

const getCookieItem = (key: string) => {
  return Cookies.get(key);
};

const setCookieItem = (key: string, value: any, ttl: number) => {
  const ttlInMinutes = (1 / 24 / 60) * ttl;
  Cookies.set(key, value, { expires: ttlInMinutes });
};

const removeCookieItem = (key: string) => {
  Cookies.remove(key);
};

const COOKIE_ITEMS = {
  signIn: 'signin',
  access_token: 'access_token'
};

export {
  getCookieItem,
  setCookieItem,
  removeCookieItem,
  COOKIE_ITEMS,
};
