import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";



export default function MobileMenu() {
  const pathname = usePathname();
  const { push } = useRouter();

  const MENU_ITEMS = [
    { label: '리포트', url: '/my-place/report', icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chart-infographic" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.2" stroke={pathname.startsWith('/my-place') ? '#444444' : '#6B7280'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                              <path d="M7 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                                              <path d="M7 3v4h4" />
                                                              <path d="M9 17l0 4" />
                                                              <path d="M17 14l0 7" />
                                                              <path d="M13 13l0 8" />
                                                              <path d="M21 12l0 9" />
                                                            </svg>
    },
    { label: '대시보드', url: '/dashboard', icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chart-line" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.2" stroke={pathname.startsWith('/keyword-search') ? '#444444' : '#6B7280'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                  <path d="M4 19l16 0" />
                                                  <path d="M4 15l4 -6l4 2l4 -5l4 4" />
                                                </svg>
    },
    { label: '키워드', url: '/keyword-search', icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-search" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.2" stroke={pathname.startsWith('/keyword-search') ? '#444444' : '#6B7280'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                      <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                                      <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                                      <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                      <path d="M8 11h4" />
                                                      <path d="M8 15h3" />
                                                      <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                      <path d="M18.5 19.5l2.5 2.5" />
                                                    </svg>
    },
    { label: '포스터', url: '/poster-template', icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.2" stroke={pathname.startsWith('/poster-template') ? '#444444' : '#6B7280'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M15 8h.01" />
                                                        <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
                                                        <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
                                                        <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
                                                      </svg>
    },
    { label: '내 정보', url: '/my-account', icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="21" height="21" viewBox="0 0 24 24" strokeWidth="1.2" stroke={pathname.startsWith('/my-account') ? '#444444' : '#6B7280'} fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                  </svg>
    },
  ]

  
  return (
    <>
      <nav className="md:hidden h-14 bg-white w-full fixed bottom-0 shadow-[0_-1.5px_0_0_rgb(0,0,0,0.1)] z-[100]">
        <ul className="flex py-1 justify-around">
          {MENU_ITEMS.map(item => (
            <li key={item.label} className={`rounded-lg p-1`}>
              <div className="flex flex-col gap-1 items-center w-12" onClick={()=> push(item.url)}>
                {item.icon}
                <div className={`${pathname.startsWith(item.url) ? 'text-gray-800' : 'text-gray-500'} ${pathname.startsWith(item.url) ? 'font-semibold' : 'font-medium'}  text-[11px] text-center`}>
                  {item.label}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}
