'use client';

import React from "react";
import { Button, Menu } from "@mantine/core";
import { usePathname, useRouter } from 'next/navigation'
import themeVariables from "@/config/theme/themeVariable";
import Link from "next/link";
import { images } from "@/utils/images";
import { useAtom, useAtomValue } from "jotai";
import * as atoms from '@/config/store/globalStore'
import { BuildingStorefrontIcon, ChevronDownIcon, Cog8ToothIcon, IdentificationIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import { handleSignOut } from "@/utils/auth";
import { Membership } from "@/utils/payment";



export default function NavigationBar() {
  const pathname = usePathname();
  const { push } = useRouter();
  

  const MENU_ITEMS = [
    { label: '플레이스 리포트', url: '/my-place/report' },
    { label: '대시보드', url: '/dashboard' },
    { label: '키워드 분석', url: '/keyword-search' },
    { label: '포스터 템플릿', url: '/poster-template' },
  ]

  
  const MobileNavbar = () => {
    const userInfo = useAtomValue(atoms.userInfo)
    const signIn = useAtomValue(atoms.signIn)

    return (
      <nav className="z-[100] w-full flex md:hidden h-11 items-center sticky top-0 bg-white">
        <Link href={'/'} className="flex gap-1.5 items-center px-4" scroll={false}>
          <img className="w-9 h-9" src={images.logo}/>
          <div className="font-bold text-sm text-gray-900">데피니션랩</div>
        </Link>
        { (!signIn || userInfo.tier == Membership.free) &&
          <Link className="ml-auto mx-4 text-gray-700 text-[13px] font-medium bg-gray-100 py-1 px-2 rounded-lg hover:text-gray-800" href={'/my-account/membership'}>
            멤버쉽 가입하기
          </Link>
        }
      </nav>
    )
  }

  const DesktopNavBar = () => (
    <nav className="z-[100] w-full hidden md:flex h-auto items-center justify-center data-[menu-open=true]:border-none sticky top-0 inset-x-0 border-b border-divider backdrop-blur-lg data-[menu-open=true]:backdrop-blur-xl backdrop-saturate-150 bg-background/70">
      <header className="z-[100] flex gap-4 w-full flex-row relative flex-nowrap items-center justify-between h-16 max-w-[1280px]">
        <div className="h-full px-6 flex-row flex-nowrap items-center data-[justify=start]:justify-start data-[justify=start]:flex-grow data-[justify=start]:basis-0 data-[justify=center]:justify-center data-[justify=end]:justify-end data-[justify=end]:flex-grow data-[justify=end]:basis-0 hidden md:flex gap-8">
          <div className="flex gap-2 align-middle items-center cursor-pointer mr-8" onClick={() => push('/')}>
            <img className="w-14" src={images.logo}/>
            <div className="font-bold text-base">데피니션랩</div>
          </div>
          {MENU_ITEMS.map((item) => (
            <Link
              key={item.label}
              href={item.url}
              className={`text-gray-700 ${pathname == item.url ? 'font-semibold' : 'font-normal'} hover:text-gray-500`}
            >
              {item.label}
            </Link>
          ))}
        </div>
        <LoginButton/>
      </header>
    </nav>
  )


  return (
    <>
      <MobileNavbar/>
      <DesktopNavBar/>
    </>
  );
}

const LoginButton = () => {
  const userInfo = useAtomValue(atoms.userInfo)
  const [ signIn, setSignIn ] = useAtom(atoms.signIn)
  const { push } = useRouter();

  if (signIn == null) {
    return <div className="w-[90px]"/>
  }
  
  if (!signIn) {
    return (
      <Link href="/login" className="pr-2 md:pr-0">
        <Button classNames={themeVariables.button.transparent}>
          로그인
        </Button>
      </Link>
    )
  }

  return (
    <Menu classNames={{ item: '!py-2'}} shadow={'sm'} trigger={'click-hover'} width={180} position={'bottom-end'} zIndex={101}>
      <Menu.Target>
        <div className="flex justify-end min-w-fit pr-4 gap-2 max-w-[120px] line-clamp-1 items-center font-normal text-[15px] text-gray-700 cursor-pointer">
          {userInfo.name}님
          <ChevronDownIcon className="w-3.5 h-3.5 stroke-[3px] text-gray-600"/>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<Cog8ToothIcon className="w-4 h-4 text-gray-700"/>}
          onClick={() => push('/my-account/profile')}
        >
          계정 설정
        </Menu.Item>
        <Menu.Item 
          leftSection={<IdentificationIcon className="w-4 h-4 text-gray-700"/>}
          onClick={() => push('/my-account/membership')}
        >
          멤버쉽 정보
        </Menu.Item>
        <Menu.Item 
          leftSection={<BuildingStorefrontIcon className="w-4 h-4 text-gray-700"/>}
          onClick={() => push('/my-place/register')}
        >
          플레이스 설정
        </Menu.Item>
        <Menu.Item
          leftSection={<ReceiptPercentIcon className="w-4 h-4 text-gray-700"/>}
          onClick={() => push('/my-account/invitation')}
        >
          추천인 코드
        </Menu.Item>
        <Menu.Item
          leftSection={<ArrowLeftOnRectangleIcon className="w-4 h-4 text-gray-700"/>}
          onClick={async () => {
            await handleSignOut();
          }}
        >
          로그아웃
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}