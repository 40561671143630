import { API_ENDPOINT, CLIENT_ENDPOINT } from "@/config/endpoint/endpoint";

// eslint-disable-next-line react-hooks/rules-of-hooks
export async function handleSignIn() {
  const KAKAO_CLIENT_ID = process.env.NEXT_PUBLIC_KAKAO_CLIENT_ID;
  const SERVER_ENDPOINT = process.env.NEXT_PUBLIC_SERVER_ENDPOINT;
  const LOGIN_CALLBACK_ENDPOINT = `${SERVER_ENDPOINT}/account/login`;

  const url = window.location.search;
  const searchParams = new URLSearchParams(url);
  const redirect = searchParams.get('redirect') || '/'

  const kakao_auth_link = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_CLIENT_ID}&redirect_uri=${LOGIN_CALLBACK_ENDPOINT}&state=${redirect}`;
  window.location.assign(kakao_auth_link);
}

export async function handleSignOut() {
  await API_ENDPOINT.account.logout()
  window.location.href = `${CLIENT_ENDPOINT}/`
}